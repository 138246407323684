import React from "react";

export const InstagramLogo = ({ color }) => {
  return (
    <svg
      className={`w-full h-auto max-h-full ${color}`}
      xmlSpace="preserve"
      viewBox="280.89 393.69 20.14 20.14"
    >
      <path
        id="fullLogo_00000024701274612360044420000007530870497194861712_"
        d="M290.97 398.43c-2.91 0-5.29 2.37-5.29 5.29 0 2.91 2.37 5.28 5.29 5.28 2.91 0 5.28-2.37 5.28-5.28 0-2.92-2.37-5.29-5.28-5.29m0 8.85a3.57 3.57 0 0 1-3.57-3.56c0-1.97 1.6-3.57 3.57-3.57 1.97 0 3.57 1.6 3.57 3.57a3.583 3.583 0 0 1-3.57 3.56"
      />
      <path
        id="fullLogo_00000124850339292312155860000017234633130594939286_"
        d="M295.35 393.69h-8.78c-3.13 0-5.68 2.55-5.68 5.68v8.78c0 3.13 2.55 5.68 5.68 5.68h8.78c3.13 0 5.68-2.55 5.68-5.68v-8.78c0-3.14-2.54-5.68-5.68-5.68m3.96 14.45a3.97 3.97 0 0 1-3.96 3.96h-8.78a3.97 3.97 0 0 1-3.96-3.96v-8.78a3.97 3.97 0 0 1 3.96-3.96h8.78a3.97 3.97 0 0 1 3.96 3.96v8.78z"
      />
      <path
        id="fullLogo_00000159441815893977448260000004074409446948058553_"
        d="M296.33 397.23a1.16 1.16 0 1 0 .002 2.322 1.16 1.16 0 0 0-.002-2.322"
      />
    </svg>
  );
};
