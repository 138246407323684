import React from "react";

export const PinterestLogo = ({ color }) => {
  return (
    <svg
      className={`w-full h-auto max-h-full ${color}`}
      xmlSpace="preserve"
      viewBox="267.2 383.78 17.8 22.32"
    >
      <path d="M270.61 404.5c.08-1.12.96-7.42 2.16-9.96-1.27-2.55.37-6.77 3.28-5.21 1.82 1.09-.76 5.72-.65 6.63.07 1.27 1.02 2.95 3.46 1.82 3.35-1.64 3.86-8.45 1.41-10.47-2.43-2.14-6.43-1.85-9.27 1.22-2.02 2.33-1.44 5.93-.15 6.92.85.82.07 2.11-.69 1.87-1.75-.38-4.33-3.91-2.08-8.72 2.22-4.44 9.61-6.6 13.98-3.02 5.24 4.4 2.26 10.85.33 12.89-2.69 2.62-5.6 3.1-7.75.38-.18 1-1.62 5.05-2.43 6.25-.39.85-1.85 2-1.6-.6z" />
    </svg>
  );
};
