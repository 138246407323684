import React from "react";

export const Logo = () => {
  return (
    <span>
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="230.1 312.99 158.09 130.71"
        className="w-full h-auto"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`.st4{fill:#E7D3E6;}
	.st1{fill:#5D3754;}
	.st2{fill:#67823A;}
	.st3{fill:#141B4D;}`}
        </style>
        <g>
          <g>
            <path
              className="st4"
              d="M301.31,317.78c12.83,8.28,16.01,30.32,3.96,41.36v18.62h12.84l-0.35-60.72c3.34-0.01,19.44-0.12,21.58,0.1
			c16.98,1.72,19.27,13.1,16.87,23.88c-3.44,11.38-8.48,14.28-25.2,14.77c-1.86,0.06-3.27,0.08-4.76,0.08v2.82
			c31.39-1.03,35.7,16.66,36.77,19.06h14.32c-4.85-11.96-15.11-19.75-27.61-21.61c6.59-0.7,18.37-8.24,18.97-19.92
			c-0.39-16.17-13.08-22.96-33.08-23.06l-44.32-0.17C295.07,314.01,297.38,315.05,301.31,317.78z"
            />
            <path
              className="st4"
              d="M243.45,327.29v50.48h13.07v-9.73c-0.25-0.02-0.2-0.04,0.11-0.05v-2.9h-0.11v-47.78
			c0.19,0,6.89,0.01,6.89,0.01c19.2,0,28.81,8.15,28.81,24.45c0,7.49-2.25,13.26-6.74,17.28c-4.5,4.03-10.54,6.04-18.13,6.04h-3.37
			v2.83c2.45-0.01,10.43,0.02,11.27-0.04c0.29,3.35,0.73,6.81,1.29,9.9h12.77c-1.19-5.32-1.93-9.56-2.49-11.76l0,0
			c12.9-4.07,18.46-15.12,18.46-15.12c4.42-8.26,2.64-17.54-1.54-23.97c-3.67-5.45-10.02-9.28-19.05-11.51
			c-6.23-1.54-13.74-2.31-22.53-2.31H230.1v3.23c4.5,0,7.73,0.66,9.7,1.97C242.23,319.98,243.45,322.98,243.45,327.29z"
            />
            <path
              className="st4"
              d="M305.51,410.08c2.16,3.56,6.23,8.5,12.77,13.07v-23.3h-12.89L305.51,410.08z"
            />
            <path
              className="st4"
              d="M256.35,399.84h-12.89c-0.14,4.03-0.79,8.7-3.66,11.02c-1.78,1.44-5.1,1.74-9.7,1.74v3.5h39.77v-3.5
			c-4.59,0-7.82-0.6-9.55-1.8C257.13,408.62,256.42,404.08,256.35,399.84z"
            />
            <path
              className="st4"
              d="M365.98,399.84c0.74,16.95-8.09,31.28-30.82,31.63c-20.82,0.21-32.43-15.41-39.13-31.63H283.3
			c22.99,46.18,83.68,43.82,95.42,6.91c0.67-2.43,0.92-4.57,1.06-6.91H365.98z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M322.02,435.68l1.2,0.3c1.45,0.37,1.99,1.36,1.69,2.56c-0.3,1.17-1.25,1.8-2.7,1.44l-0.64-0.16l-0.34,1.32
			l-0.56-0.14L322.02,435.68z M322.31,439.47c1.19,0.3,1.8-0.21,2.02-1.08c0.22-0.88-0.06-1.61-1.26-1.92l-0.61-0.15l-0.76,3
			L322.31,439.47z"
            />
            <path
              className="st1"
              d="M328.91,437.23l0.57,0.08l-0.43,3.06l2.91,0.41l0.43-3.06l0.58,0.08l-0.76,5.43l-0.57-0.08l0.26-1.84
			l-2.9-0.41l-0.26,1.84l-0.58-0.08L328.91,437.23z"
            />
            <path
              className="st1"
              d="M336.45,440.96c-0.04-1.65,1.19-2.83,2.75-2.87c1.56-0.03,2.85,1.09,2.88,2.74c0.04,1.65-1.19,2.83-2.76,2.87
			C337.77,443.74,336.48,442.62,336.45,440.96z M341.48,440.85c-0.03-1.31-0.99-2.26-2.27-2.23c-1.27,0.03-2.19,1.02-2.16,2.33
			c0.03,1.3,0.99,2.26,2.26,2.23C340.59,443.15,341.51,442.16,341.48,440.85z"
            />
            <path
              className="st1"
              d="M346.36,437.87l-1.65,0.33l-0.1-0.51l3.87-0.77l0.1,0.51l-1.65,0.33l0.97,4.87l-0.57,0.11L346.36,437.87z"
            />
            <path
              className="st1"
              d="M351.97,438.86c-0.54-1.56,0.27-3.06,1.74-3.57c1.48-0.51,3.04,0.17,3.58,1.73c0.54,1.56-0.28,3.06-1.75,3.57
			C354.08,441.1,352.51,440.42,351.97,438.86z M356.73,437.22c-0.43-1.23-1.63-1.85-2.84-1.43c-1.2,0.42-1.77,1.64-1.34,2.88
			c0.42,1.23,1.63,1.85,2.83,1.44C356.59,439.68,357.15,438.45,356.73,437.22z"
            />
            <path
              className="st1"
              d="M360.57,435.6c-0.77-1.46-0.22-3.05,1.19-3.79c0.7-0.37,1.4-0.48,2.02-0.26l-0.17,0.53
			c-0.48-0.16-1.01-0.12-1.61,0.19c-1.14,0.6-1.51,1.89-0.9,3.05c0.61,1.16,1.89,1.58,3.06,0.97c0.66-0.35,1.03-0.79,1.2-1.19
			l-0.52-1l-1.44,0.76l-0.24-0.46l1.93-1.01l0.89,1.69c-0.17,0.54-0.65,1.2-1.57,1.68C362.97,437.51,361.34,437.06,360.57,435.6z"
            />
            <path
              className="st1"
              d="M372.86,431.19l-0.55,0.41l-1.74-0.57c-0.13,0.14-0.28,0.27-0.44,0.38l-0.52,0.39l0.86,1.16l-0.47,0.34
			l-3.26-4.4l0.99-0.73c1.2-0.89,2.32-0.68,3.03,0.27c0.49,0.66,0.56,1.41,0.14,2.11L372.86,431.19z M369.8,431.02
			c1-0.74,0.99-1.52,0.47-2.22c-0.51-0.69-1.27-0.93-2.26-0.2l-0.5,0.37l1.79,2.42L369.8,431.02z"
            />
            <path
              className="st1"
              d="M373.36,423.18l0.07-0.07l5.62,2.11l-0.42,0.45l-0.92-0.36l-1.9,2.02l0.42,0.89l-0.39,0.42L373.36,423.18z
			 M377.19,425.11l-2.43-0.97c-0.26-0.1-0.62-0.29-0.62-0.29s0.21,0.35,0.32,0.6l1.11,2.37L377.19,425.11z"
            />
            <path
              className="st1"
              d="M376.94,418.87l0.64-1.07c0.77-1.29,1.87-1.52,2.93-0.89c1.04,0.62,1.37,1.71,0.6,3l-0.34,0.57l1.17,0.7
			l-0.3,0.5L376.94,418.87z M380.66,419.68c0.63-1.06,0.32-1.78-0.46-2.25c-0.78-0.46-1.57-0.4-2.2,0.66l-0.32,0.54l2.66,1.59
			L380.66,419.68z"
            />
            <path
              className="st1"
              d="M380.07,412.86l0.17-0.56l2.96,0.89l0.84-2.81l-2.96-0.88l0.17-0.56l5.25,1.57l-0.17,0.56l-1.79-0.53
			l-0.84,2.81l1.78,0.53l-0.17,0.56L380.07,412.86z"
            />
            <path
              className="st1"
              d="M386.41,403.98l-4,1.56l0.09-0.65l2.89-1.12c0.22-0.09,0.58-0.16,0.58-0.16s-0.32-0.16-0.52-0.3l-2.51-1.82
			l0.08-0.6l3.44,2.5l1.73,0.23l-0.08,0.59L386.41,403.98z"
            />
          </g>
          <path
            className="st2"
            d="M376.95,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C377.51,378.63,377.26,378.88,376.95,378.88z M374.7,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C375.25,378.63,375.01,378.88,374.7,378.88z M372.44,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C373,378.63,372.75,378.88,372.44,378.88z M370.19,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.3,0,0.56,0.25,0.56,0.56
		C370.75,378.63,370.5,378.88,370.19,378.88z M367.94,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C368.49,378.63,368.25,378.88,367.94,378.88z M365.69,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C366.24,378.63,365.99,378.88,365.69,378.88z M363.43,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C363.99,378.63,363.74,378.88,363.43,378.88z M361.18,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C361.74,378.63,361.49,378.88,361.18,378.88z M358.93,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C359.48,378.63,359.23,378.88,358.93,378.88z M356.67,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C357.23,378.63,356.98,378.88,356.67,378.88z M354.42,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C354.98,378.63,354.73,378.88,354.42,378.88z M352.17,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C352.72,378.63,352.47,378.88,352.17,378.88z M349.91,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C350.47,378.63,350.22,378.88,349.91,378.88z M347.66,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C348.21,378.63,347.97,378.88,347.66,378.88z M345.41,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C345.96,378.63,345.72,378.88,345.41,378.88z M343.15,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C343.71,378.63,343.46,378.88,343.15,378.88z M340.9,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C341.46,378.63,341.21,378.88,340.9,378.88z M338.65,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C339.2,378.63,338.95,378.88,338.65,378.88z M336.39,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C336.95,378.63,336.7,378.88,336.39,378.88z M334.14,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C334.7,378.63,334.45,378.88,334.14,378.88z M331.89,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C332.44,378.63,332.2,378.88,331.89,378.88z M329.64,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C330.19,378.63,329.94,378.88,329.64,378.88z M327.38,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C327.94,378.63,327.69,378.88,327.38,378.88z M325.13,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C325.68,378.63,325.44,378.88,325.13,378.88z M322.87,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C323.43,378.63,323.18,378.88,322.87,378.88z M320.62,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C321.18,378.63,320.93,378.88,320.62,378.88z M318.37,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C318.92,378.63,318.68,378.88,318.37,378.88z M316.12,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C316.67,378.63,316.42,378.88,316.12,378.88z M313.86,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C314.42,378.63,314.17,378.88,313.86,378.88z M311.61,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C312.17,378.63,311.92,378.88,311.61,378.88z M309.36,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C309.91,378.63,309.66,378.88,309.36,378.88z M307.1,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C307.66,378.63,307.41,378.88,307.1,378.88z M304.85,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C305.41,378.63,305.16,378.88,304.85,378.88z M302.6,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C303.15,378.63,302.9,378.88,302.6,378.88z M300.34,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C300.9,378.63,300.65,378.88,300.34,378.88z M298.09,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C298.65,378.63,298.4,378.88,298.09,378.88z M295.84,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C296.39,378.63,296.14,378.88,295.84,378.88z M293.58,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C294.14,378.63,293.89,378.88,293.58,378.88z M291.33,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C291.89,378.63,291.64,378.88,291.33,378.88z M289.08,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C289.63,378.63,289.38,378.88,289.08,378.88z M286.82,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C287.38,378.63,287.13,378.88,286.82,378.88z M284.57,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C285.13,378.63,284.88,378.88,284.57,378.88z M282.32,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C282.87,378.63,282.63,378.88,282.32,378.88z M280.06,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C280.62,378.63,280.37,378.88,280.06,378.88z M277.81,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C278.37,378.63,278.12,378.88,277.81,378.88z M275.56,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C276.11,378.63,275.87,378.88,275.56,378.88z M273.31,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C273.86,378.63,273.61,378.88,273.31,378.88z M271.05,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C271.61,378.63,271.36,378.88,271.05,378.88z M268.8,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C269.36,378.63,269.11,378.88,268.8,378.88z M266.55,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C267.1,378.63,266.85,378.88,266.55,378.88z M264.29,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C264.85,378.63,264.6,378.88,264.29,378.88z M262.04,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C262.6,378.63,262.35,378.88,262.04,378.88z M259.79,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C260.34,378.63,260.09,378.88,259.79,378.88z M257.53,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C258.09,378.63,257.84,378.88,257.53,378.88z M255.28,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C255.84,378.63,255.59,378.88,255.28,378.88z M253.03,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C253.58,378.63,253.33,378.88,253.03,378.88z M250.77,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C251.33,378.63,251.08,378.88,250.77,378.88z M248.52,378.88
		c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C249.08,378.63,248.83,378.88,248.52,378.88z M246.27,378.88c-0.31,0-0.57-0.25-0.57-0.55c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C246.83,378.63,246.58,378.88,246.27,378.88z M244.01,378.88c-0.31,0-0.57-0.25-0.57-0.55
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C244.57,378.63,244.32,378.88,244.01,378.88z"
          />
          <path
            className="st2"
            d="M379.23,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C379.79,399.58,379.54,399.83,379.23,399.83z M376.97,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C377.53,399.58,377.28,399.83,376.97,399.83z M374.72,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56C375.28,399.58,375.03,399.83,374.72,399.83z M372.47,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C373.02,399.58,372.77,399.83,372.47,399.83z M370.22,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C370.77,399.58,370.52,399.83,370.22,399.83z M367.96,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C368.52,399.58,368.27,399.83,367.96,399.83z M365.71,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C366.26,399.58,366.02,399.83,365.71,399.83z M363.46,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C364.01,399.58,363.76,399.83,363.46,399.83z M361.2,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C361.76,399.58,361.51,399.83,361.2,399.83z M358.95,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C359.51,399.58,359.26,399.83,358.95,399.83z M356.7,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C357.25,399.58,357,399.83,356.7,399.83z M354.44,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C355,399.58,354.75,399.83,354.44,399.83z M352.19,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C352.75,399.58,352.5,399.83,352.19,399.83z M349.94,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C350.49,399.58,350.24,399.83,349.94,399.83z M347.69,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.3,0,0.55,0.25,0.55,0.56C348.24,399.58,347.99,399.83,347.69,399.83z M345.43,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C345.99,399.58,345.74,399.83,345.43,399.83z M343.18,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C343.73,399.58,343.48,399.83,343.18,399.83z M340.92,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C341.48,399.58,341.23,399.83,340.92,399.83z M338.67,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C339.23,399.58,338.98,399.83,338.67,399.83z M336.42,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C336.97,399.58,336.72,399.83,336.42,399.83z M334.17,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C334.72,399.58,334.47,399.83,334.17,399.83z M331.91,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C332.47,399.58,332.22,399.83,331.91,399.83z M329.66,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C330.21,399.58,329.97,399.83,329.66,399.83z M327.41,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C327.96,399.58,327.71,399.83,327.41,399.83z M325.15,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C325.71,399.58,325.46,399.83,325.15,399.83z M322.9,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.3,0,0.56,0.25,0.56,0.56C323.45,399.58,323.2,399.83,322.9,399.83z M320.65,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C321.2,399.58,320.95,399.83,320.65,399.83z M318.39,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C318.95,399.58,318.7,399.83,318.39,399.83z M316.14,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C316.69,399.58,316.45,399.83,316.14,399.83z M313.88,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C314.44,399.58,314.19,399.83,313.88,399.83z M311.63,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C312.19,399.58,311.94,399.83,311.63,399.83z M309.38,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C309.94,399.58,309.69,399.83,309.38,399.83z M307.13,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C307.68,399.58,307.43,399.83,307.13,399.83z M304.87,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C305.43,399.58,305.18,399.83,304.87,399.83z M302.62,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C303.18,399.58,302.93,399.83,302.62,399.83z M300.37,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C300.92,399.58,300.67,399.83,300.37,399.83z M298.11,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C298.67,399.58,298.42,399.83,298.11,399.83z M295.86,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C296.42,399.58,296.17,399.83,295.86,399.83z M293.61,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C294.17,399.58,293.91,399.83,293.61,399.83z M291.35,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C291.91,399.58,291.66,399.83,291.35,399.83z M289.1,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C289.66,399.58,289.41,399.83,289.1,399.83z M286.85,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C287.41,399.58,287.15,399.83,286.85,399.83z M284.6,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C285.15,399.58,284.9,399.83,284.6,399.83z M282.34,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.55,0.25,0.55,0.56C282.9,399.58,282.65,399.83,282.34,399.83z M280.09,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56C280.64,399.58,280.4,399.83,280.09,399.83z M277.84,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C278.39,399.58,278.14,399.83,277.84,399.83z M275.58,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C276.14,399.58,275.89,399.83,275.58,399.83z M273.33,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C273.88,399.58,273.64,399.83,273.33,399.83z M271.08,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.55,0.25,0.55,0.56
		C271.63,399.58,271.38,399.83,271.08,399.83z M268.82,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C269.38,399.58,269.13,399.83,268.82,399.83z M266.57,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C267.13,399.58,266.88,399.83,266.57,399.83z M264.32,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C264.87,399.58,264.62,399.83,264.32,399.83z M262.06,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C262.62,399.58,262.37,399.83,262.06,399.83z M259.81,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C260.37,399.58,260.12,399.83,259.81,399.83z M257.56,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C258.11,399.58,257.86,399.83,257.56,399.83z M255.3,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C255.86,399.58,255.61,399.83,255.3,399.83z M253.05,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56C253.61,399.58,253.36,399.83,253.05,399.83z M250.8,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C251.35,399.58,251.1,399.83,250.8,399.83z M248.54,399.83c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.24-0.56,0.54-0.56h0.03
		c0.31,0,0.56,0.25,0.56,0.56C249.1,399.58,248.85,399.83,248.54,399.83z M246.29,399.83c-0.31,0-0.57-0.25-0.57-0.56
		c0-0.31,0.23-0.56,0.54-0.56h0.03c0.3,0,0.55,0.25,0.55,0.56C246.85,399.58,246.6,399.83,246.29,399.83z M244.04,399.83
		c-0.31,0-0.57-0.25-0.57-0.56c0-0.31,0.23-0.56,0.54-0.56h0.03c0.31,0,0.56,0.25,0.56,0.56
		C244.59,399.58,244.35,399.83,244.04,399.83z"
          />
          <g>
            <path
              className="st3"
              d="M260.53,395.58l-0.04,0.44h-0.29c-1.19,0-2.24-0.43-3.15-1.28c-0.58-0.55-1.26-1.5-2.05-2.84
			c-0.22-0.41-0.57-0.99-1.06-1.72c-0.11-0.12-0.32-0.19-0.62-0.19c-1.81,0-2.62,0.01-2.44,0.02v3.64c0,0.66,0.19,1.11,0.56,1.35
			c0.26,0.17,0.73,0.25,1.41,0.25v0.48h-5.86v-0.48c0.68,0,1.15-0.1,1.43-0.29c0.36-0.23,0.54-0.67,0.54-1.3V384
			c0-0.64-0.18-1.08-0.54-1.33c-0.29-0.19-0.77-0.29-1.43-0.29v-0.48h4.72c4.46,0,6.69,1.34,6.69,4.02c0,1.68-0.97,2.86-2.9,3.52
			c1.28,2.06,2,3.2,2.15,3.42C258.84,394.57,259.79,395.47,260.53,395.58z M256.14,386.14c0-2.4-1.42-3.6-4.25-3.6h-0.81
			c-0.02,0-0.05,0-0.1,0c-0.04,0.01-0.08,0.02-0.1,0.02v7.02h1.6c1.12,0,2.01-0.3,2.67-0.89
			C255.8,388.09,256.14,387.24,256.14,386.14z"
            />
            <path
              className="st3"
              d="M275.73,395.72h-6.01v-0.41c0.45,0,0.77,0,0.98,0c0.88,0,1.32-0.27,1.32-0.81c0-0.23-0.26-1.04-0.77-2.42
			c-0.3-0.81-0.61-1.63-0.93-2.44h-4.52c-0.32,0.82-0.64,1.62-0.97,2.42c-0.5,1.31-0.75,2.11-0.75,2.4c0,0.57,0.4,0.85,1.2,0.85
			c0.19,0,0.47,0,0.83,0l0.06,0.41h-5.05v-0.46c0.64,0,1.11-0.14,1.41-0.41c0.23-0.21,0.48-0.65,0.72-1.33l3.98-10.6
			c0.14-0.37,0.21-0.66,0.23-0.85c-0.03-0.17-0.07-0.32-0.12-0.46h1.93l4.37,11.81c0.26,0.7,0.49,1.16,0.68,1.37
			c0.3,0.32,0.77,0.48,1.41,0.48V395.72z M269.99,388.83l-1.93-5.2l-1.95,5.2H269.99z"
            />
            <path
              className="st3"
              d="M288.34,392.43l-0.31,3.29h-11.66v-0.48c0.9,0,1.51-0.06,1.84-0.19c0.55-0.24,0.83-0.73,0.83-1.47V384
			c0-0.64-0.18-1.07-0.54-1.31c-0.29-0.21-0.77-0.31-1.43-0.31v-0.48h10.38l0.17,3.13h-0.52c-0.13-1.01-0.4-1.66-0.83-1.95
			c-0.35-0.25-1.06-0.37-2.13-0.37h-3.17v5.47h2.83c0.84,0,1.38-0.08,1.62-0.23c0.31-0.19,0.53-0.67,0.66-1.43h0.44v4.25h-0.44
			c-0.06-0.8-0.25-1.31-0.58-1.53c-0.25-0.17-0.81-0.25-1.68-0.25h-2.86v4.1c0,0.76,0.17,1.26,0.5,1.51
			c0.29,0.21,0.83,0.31,1.61,0.31h1.82c0.97,0,1.65-0.17,2.06-0.52c0.41-0.34,0.71-1,0.92-1.97H288.34z"
            />
            <path
              className="st3"
              d="M306.9,391.69c0,0.68-0.18,1.31-0.54,1.9c-0.54,0.9-1.38,1.51-2.53,1.82c-0.75,0.21-1.81,0.31-3.19,0.31
			h-5.84v-0.48c0.68,0,1.15-0.1,1.43-0.29c0.36-0.23,0.54-0.67,0.54-1.3V384c0-0.5-0.13-0.91-0.4-1.23
			c-0.27-0.32-0.63-0.49-1.09-0.49c-0.12,0-0.26,0.01-0.39,0.04l-0.08-0.04v-0.46h6.05c3.56,0,5.34,1.04,5.34,3.11
			c0,1.42-0.98,2.4-2.94,2.94c1.13,0.36,1.98,0.79,2.55,1.28C306.53,389.81,306.9,390.65,306.9,391.69z M304.23,385.16
			c0-1.13-0.38-1.91-1.14-2.32c-0.54-0.29-1.43-0.44-2.68-0.44c-0.18,0-0.46,0-0.85,0c-0.37,0.01-0.66,0.02-0.85,0.02v5.2h2.72
			C303.29,387.63,304.23,386.81,304.23,385.16z M304.79,391.62c0-1.12-0.39-1.97-1.16-2.57c-0.7-0.54-1.63-0.81-2.78-0.81
			c-0.23,0-0.59,0-1.08,0c-0.47,0.01-0.83,0.02-1.08,0.02v4.27c0,0.93,0.14,1.57,0.41,1.93c0.33,0.43,0.94,0.64,1.82,0.64
			c1.1,0,2.01-0.3,2.71-0.91C304.41,393.56,304.79,392.7,304.79,391.62z"
            />
            <path
              className="st3"
              d="M321.42,395.72h-6.01v-0.41c0.45,0,0.77,0,0.98,0c0.88,0,1.32-0.27,1.32-0.81c0-0.23-0.26-1.04-0.77-2.42
			c-0.3-0.81-0.61-1.63-0.93-2.44h-4.52c-0.32,0.82-0.64,1.62-0.97,2.42c-0.5,1.31-0.74,2.11-0.74,2.4c0,0.57,0.4,0.85,1.2,0.85
			c0.19,0,0.47,0,0.83,0l0.06,0.41h-5.05v-0.46c0.64,0,1.11-0.14,1.41-0.41c0.23-0.21,0.48-0.65,0.72-1.33l3.98-10.6
			c0.14-0.37,0.21-0.66,0.23-0.85c-0.03-0.17-0.07-0.32-0.12-0.46h1.93l4.37,11.81c0.26,0.7,0.49,1.16,0.68,1.37
			c0.3,0.32,0.77,0.48,1.41,0.48V395.72z M315.68,388.83l-1.93-5.2l-1.95,5.2H315.68z"
            />
            <path
              className="st3"
              d="M336.2,395.58l-0.04,0.44h-0.29c-1.19,0-2.24-0.43-3.15-1.28c-0.58-0.55-1.26-1.5-2.05-2.84
			c-0.22-0.41-0.57-0.99-1.06-1.72c-0.11-0.12-0.32-0.19-0.62-0.19c-1.81,0-2.62,0.01-2.44,0.02v3.64c0,0.66,0.19,1.11,0.56,1.35
			c0.26,0.17,0.73,0.25,1.41,0.25v0.48h-5.86v-0.48c0.68,0,1.15-0.1,1.43-0.29c0.36-0.23,0.54-0.67,0.54-1.3V384
			c0-0.64-0.18-1.08-0.54-1.33c-0.29-0.19-0.77-0.29-1.43-0.29v-0.48h4.72c4.46,0,6.69,1.34,6.69,4.02c0,1.68-0.97,2.86-2.9,3.52
			c1.28,2.06,2,3.2,2.15,3.42C334.51,394.57,335.47,395.47,336.2,395.58z M331.81,386.14c0-2.4-1.42-3.6-4.25-3.6h-0.81
			c-0.01,0-0.05,0-0.1,0c-0.04,0.01-0.08,0.02-0.1,0.02v7.02h1.6c1.12,0,2.01-0.3,2.67-0.89
			C331.48,388.09,331.81,387.24,331.81,386.14z"
            />
            <path
              className="st3"
              d="M354.03,382.39c-0.66,0-1.14,0.1-1.43,0.29c-0.36,0.25-0.54,0.69-0.54,1.32v11.89h-0.62l-11.31-11.93v9.69
			c0,0.65,0.19,1.09,0.56,1.33c0.28,0.18,0.75,0.27,1.41,0.27v0.48h-5.1v-0.48c0.68,0,1.15-0.1,1.43-0.29
			c0.36-0.23,0.54-0.67,0.54-1.3v-9.89c0-0.55-0.17-0.92-0.5-1.09c-0.15-0.08-0.59-0.18-1.31-0.29v-0.48h3.4l10.33,10.98V384
			c0-0.64-0.18-1.08-0.54-1.33c-0.29-0.19-0.77-0.29-1.43-0.29v-0.48h5.09V382.39z"
            />
            <path
              className="st3"
              d="M366.63,392.43l-0.31,3.29h-11.66v-0.48c0.9,0,1.51-0.06,1.84-0.19c0.55-0.24,0.83-0.73,0.83-1.47V384
			c0-0.64-0.18-1.07-0.54-1.31c-0.29-0.21-0.77-0.31-1.43-0.31v-0.48h10.38l0.17,3.13h-0.52c-0.12-1.01-0.4-1.66-0.83-1.95
			c-0.35-0.25-1.06-0.37-2.13-0.37h-3.17v5.47h2.83c0.84,0,1.38-0.08,1.62-0.23c0.31-0.19,0.53-0.67,0.66-1.43h0.44v4.25h-0.44
			c-0.06-0.8-0.25-1.31-0.58-1.53c-0.25-0.17-0.81-0.25-1.68-0.25h-2.86v4.1c0,0.76,0.17,1.26,0.5,1.51
			c0.29,0.21,0.83,0.31,1.62,0.31h1.82c0.97,0,1.65-0.17,2.06-0.52c0.41-0.34,0.71-1,0.92-1.97H366.63z"
            />
            <path
              className="st3"
              d="M376.59,392.2c0,1.31-0.47,2.3-1.41,2.96c-0.83,0.59-1.92,0.89-3.27,0.89c-0.3,0-0.8-0.09-1.5-0.28
			c-0.7-0.19-1.13-0.27-1.29-0.26c-0.18,0.01-0.46,0.06-0.83,0.15l-0.45-3.33l0.45,0.06c0.25,0.91,0.68,1.64,1.31,2.17
			c0.65,0.58,1.44,0.87,2.36,0.87c0.77,0,1.45-0.22,2.03-0.66c0.65-0.48,0.97-1.1,0.97-1.84c0-0.68-0.47-1.43-1.41-2.26
			c-0.4-0.36-1.34-1.04-2.82-2.05c-1.15-0.8-1.85-1.41-2.11-1.82c-0.4-0.65-0.6-1.3-0.6-1.95c0-1.08,0.43-1.91,1.28-2.48
			c0.73-0.5,1.66-0.75,2.77-0.75c0.4,0,0.93,0.09,1.6,0.27c0.66,0.18,1.08,0.27,1.26,0.27c0.14,0,0.37-0.06,0.7-0.17l0.37,2.84
			l-0.44,0.02c-0.72-1.77-1.84-2.65-3.38-2.65c-0.68,0-1.25,0.17-1.72,0.52c-0.51,0.39-0.77,0.91-0.77,1.57
			c0,0.5,0.39,1.13,1.18,1.91c0.61,0.59,1.21,1.08,1.82,1.45c1.23,0.76,2.08,1.37,2.57,1.82
			C376.15,390.31,376.59,391.22,376.59,392.2z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};
