import React from "react";

export const BasicLogo = () => {
  return (
    <svg
      xmlSpace="preserve"
      className="w-full h-auto fill-lightPurple"
      viewBox="254.23 338.8 112.02 90.42"
    >
      <path d="M363.02 385.13c-3.88-7.85-11.08-12.94-19.71-14.22 4.9-.52 13.67-6.13 14.11-14.83-.29-12.03-9.73-17.09-24.62-17.16l-32.99-.12c2.81.74 4.53 1.52 7.45 3.55 9.55 6.17 11.92 22.58 2.95 30.79l.18 37.91c1.61 2.65 4.63 6.33 9.5 9.72l-.39-78.97c2.49-.01 14.48-.09 16.06.07 12.64 1.28 14.35 9.75 12.57 17.78-2.57 8.47-6.31 10.63-18.76 11-1.38.04-2.43.06-3.55.06v2.1c23.61.49 26.38 11.65 27.18 13.44 2.58 7.38 3.1 19.16 1.46 24.55-.82 4.8-5.09 15.91-22.01 16.18-15.43.16-24.07-11.38-29.07-23.41-1.48-3.82-6.69-19.96-6.92-25.32 9.6-3.03 13.74-11.26 13.74-11.26 3.29-6.14 1.96-13.06-1.16-17.84-2.73-4.06-7.45-6.91-14.18-8.57-4.64-1.15-10.23-1.72-16.78-1.72h-23.85v2.4c3.35 0 5.75.49 7.22 1.47 1.82 1.26 2.72 3.49 2.72 6.7v54.27c-.12 2.94-.64 6.26-2.72 7.94-1.32 1.07-3.8 1.29-7.22 1.29v2.61h29.6v-2.61c-3.42 0-5.82-.45-7.11-1.34-2.32-1.6-2.88-4.87-2.95-7.97l.12-61.62h5.13c14.29 0 21.44 6.07 21.44 18.21 0 5.58-1.67 9.87-5.02 12.86-3.34 3-7.84 4.5-13.49 4.5h-2.51v2.1c1.82 0 7.76.02 8.38-.03.52 8.79 3.93 19.43 6.01 23.8h-.01c17.12 34.38 62.3 32.62 71.04 5.14 2.15-7.27 2-16.71-1.84-23.45z" />
    </svg>
  );
};
